import CreateCloudRedGuidModal from './CreateCloudRedGuidModal'
import styles from './SelectCloudRedGuids.module.styl'
import { useFetchCloudRedGuids } from './hook'
import { CloudRedGuid, type EnvironmentOptions, type SelectOption } from './types'
import { cloudRedHomepages } from './utils'
import { validateGuid } from './validate'
import { Icon, Select, Button, Link, TextGroup, Text } from '@nike/eds'
import { ReactNode, useEffect, useState } from 'react'

const overrideReactSelectOptions = {
  formatCreateLabel: (inputValue: string) => {
    // The default value is `Create` and it's a little confusing cause we're not creating a adGroup we are using it
    return `Use "${inputValue}"`
  },
  getNewOptionData: (inputValue: string, optionLabel: ReactNode | undefined) => {
    // To validate that the Guid have the right format
    const guidRegExp = validateGuid(inputValue)

    if (!guidRegExp) return null
    return { value: inputValue, label: optionLabel }
  },
}

function ErrorFetchingData() {
  return (
    <div className={styles.errorMsg}>
      <Icon className={styles.icon} name='AlertCircle' size='m' />
      <span className={styles.msg}> Error fetching CloudRed Guids</span>
    </div>
  )
}

const fetchErrorMsg =
  'Please provide a valid GUID format e.g. `12345678-abcd-ef34-1234-123456789012`'

const cloudRedUrls = {
  qa: 'https://cloudred-staging.nike.com',
  prod: 'https://cloudred.nike.com',
}

type SelectCloudRedGuidsProps = {
  accessToken: string
  userEmail: string
  env?: EnvironmentOptions | undefined
  id?: string
  label?: string
  subtitle?: string
  placeholder?: string
  isMulti?: boolean
  isCreatable?: boolean
  value?: SelectOption | SelectOption[] | undefined
  onChange?: (val: SelectOption | SelectOption[] | undefined) => void
}

export default function SelectCloudRedGuids({
  accessToken,
  userEmail,
  env = 'prod',
  id = 'CloudRedGuids',
  label = 'CloudRed Guids',
  subtitle = 'Select the CloudRed GUIDs',
  placeholder = 'cloudred guids ...',
  isMulti = true,
  isCreatable = true,
  value,
  onChange,
  ...props
}: SelectCloudRedGuidsProps) {
  const [cloudRedGuidsOptions, setCloudRedGuidsOptions] = useState<SelectOption[] | undefined>([])
  const [selectedValue, setSelectedValue] = useState<SelectOption | SelectOption[] | undefined>(
    value
  )
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const [createdCloudRedGuid, setCreatedCloudRedGuid] = useState<CloudRedGuid | null>(null)

  const { data, loading, error } = useFetchCloudRedGuids({ accessToken, userEmail, env })

  useEffect(() => {
    setFetchError(error ? fetchErrorMsg : null)
    const selectOptions = data
      ?.sort((a, b) => a.name?.localeCompare(b.name || '', 'en', { sensitivity: 'base' }) ?? 0)
      ?.map(({ name, guid }) => ({
        value: guid,
        label: `${guid} - (${name})`,
      }))
    setCloudRedGuidsOptions(selectOptions)
  }, [loading, data, error, accessToken, userEmail])

  const cloudRedSystemsPage = `${cloudRedHomepages[env]}system/index`

  return (
    <>
      <Select
        {...{ id, label, subtitle, placeholder, isMulti, isCreatable }}
        value={selectedValue}
        onChange={(val) => {
          setSelectedValue(val as SelectOption[])
          if (onChange) onChange(val as SelectOption[])
        }}
        options={cloudRedGuidsOptions}
        {...props}
        {...overrideReactSelectOptions}
        noOptionsMessage={() => {
          if (loading) return 'Loading...'
          if (fetchError) return <ErrorFetchingData />
          return 'Please provide a valid Guid format.'
        }}
      />
      <TextGroup>
        {createdCloudRedGuid && (
          <Text className={'eds-spacing--mt-8'}>
            The system <b>{createdCloudRedGuid.name}</b> has been created. Click{' '}
            <Link href={`${cloudRedUrls[env]}/system/${createdCloudRedGuid.guid}`} target='_blank'>
              here
            </Link>{' '}
            to manage it or add more tags.
          </Text>
        )}
        <Text className={`${styles.helpText} eds-spacing--mt-8`}>
          Not seeing what you're looking for? Paste a{' '}
          <Link href={cloudRedSystemsPage} target='_blank' rel='noreferrer'>
            CloudRed System ID
            <Icon name='ExternalLink' size='s' />
          </Link>{' '}
          above or{' '}
          <Button
            variant='ghost'
            size='small'
            data-external-id='select-cloudred-guid-create-guid'
            onClick={() => setCreateModalVisible(true)}
          >
            Create a CloudRed GUID
          </Button>
          .
        </Text>
      </TextGroup>
      <CreateCloudRedGuidModal
        visible={createModalVisible}
        setVisible={setCreateModalVisible}
        onChange={(cloudRedGuid) => {
          const { guid, name } = cloudRedGuid
          const value = {
            value: guid,
            label: `${guid} - (${name})`,
          }
          setCloudRedGuidsOptions([value, ...cloudRedGuidsOptions!])
          setSelectedValue(value)
          setCreatedCloudRedGuid(cloudRedGuid)
          if (onChange) onChange(value)
        }}
        accessToken={accessToken}
        env={env}
        userEmail={userEmail}
      />
    </>
  )
}
