export const headers = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

export const devPortalUrls = {
  qa: 'https://api-developer.preprod.niketech.com/v1',
  prod: 'https://api-developer.niketech.com/prod/v1',
}

const oauthAppsApi = {
  /**
   * @param string accessToken - Okta JWT for the right environment prod|qa
   * @param string userEmail - The email associated with the Okta JWT
   * @param string env - Defaults to prod
   * @returns array - Array of clientIds
   */
  listClientIds: async (
    accessToken: string,
    userEmail: string,
    env: string = 'prod'
  ): Promise<string[]> => {
    try {
      const url = `${devPortalUrls[env as keyof typeof devPortalUrls]}/apps?username=${userEmail}`
      const response = await fetch(url, {
        method: 'GET',
        headers: headers(accessToken),
      })
      if (!response?.ok) Promise.reject(response)
      const data = await response.json()

      if (data.items && Array.isArray(data.items)) {
        const listClientIds = data.items.map(({ clientId }: { clientId: string }) => clientId)
        return listClientIds
      } else {
        return []
      }
    } catch (error) {
      console.log('[API]', error)
      throw new Error(error as string)
    }
  },
}

export default oauthAppsApi
