import { Button, Icon, Link, Modal, TextGroup, Text } from '@nike/eds'
import { useState, ReactNode } from 'react'

export function IDLockerHint({ customMessage }: { customMessage?: ReactNode | string }) {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  return (
    <>
      <Text className={'eds-spacing--mt-8'}>
        Not seeing what you&rsquo;re looking for?{' '}
        <Button variant='ghost' size='small' onClick={() => setModalVisible(true)}>
          Join or create an AD group
        </Button>
      </Text>

      <Modal
        data-testid={'idlocker-modal'}
        isOpen={isModalVisible}
        onDismiss={() => setModalVisible(false)}
        headerSlot={'Join or create an AD group'}
        footerSlot={
          <Button size='small' onClick={() => setModalVisible(false)}>
            Close
          </Button>
        }
      >
        <TextGroup>
          {customMessage && <div className='eds-spacing--mb-32'>{customMessage}</div>}

          <Text>
            Use{' '}
            <Link
              href='https://idlocker.nike.com/identityiq/'
              target='blank'
              rel='noreferrer'
              className='eds-link'
            >
              IDLocker <Icon name='ExternalLink' size='s' />
            </Link>{' '}
            to request access to a group.
          </Text>
          <Text>
            If you need to create a new one, follow these instructions on{' '}
            <Link
              href='https://niketech.service-now.com/now/nav/ui/classic/params/target/kb_view.do%3Fsysparm_article%3DKB0023042'
              target='blank'
              rel='noreferrer'
              className='eds-link'
            >
              how to create an AD group <Icon name='ExternalLink' size='s' />
            </Link>
            .
          </Text>
        </TextGroup>
      </Modal>
    </>
  )
}
