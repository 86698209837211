import { waffleIronApi } from './api'
import { FetchDataCallback, UseFetchWaffleIronAccounts, WaffleIronAccount } from './types'
import React from 'react'

export function useFetchWaffleIronAccounts({
  username,
  accessToken,
  env,
  service = waffleIronApi.listByUser,
}: UseFetchWaffleIronAccounts) {
  const [data, setData] = React.useState<WaffleIronAccount[] | null>(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<Error | null>(null)

  const fetchData = React.useCallback(
    async ({ username, accessToken, env }: FetchDataCallback) => {
      setLoading(true)
      setError(null)

      try {
        const response = await service({ username, accessToken, env })
        setData(response)
      } catch (error: unknown) {
        console.log('[HOOK]', error)
        setError(new Error(error as string))
      } finally {
        setLoading(false)
      }
    },
    [service]
  )

  React.useEffect(() => {
    fetchData({ username, accessToken, env })
  }, [fetchData, username, accessToken, env])
  return { data, loading, error, fetchData }
}
