import styles from './SelectEmails.module.styl'
import aegisApi from './api'
import { UserView } from './types'
import { Label, Text } from '@nike/eds'
import { useState } from 'react'
import React from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

export interface SelectEmailsProps {
  accessToken: string
  env?: string
  id?: string
  label?: string
  placeholder?: string
  subtitle?: string
  required?: boolean
  labelKey?: string
  hasErrors?: boolean
  errorMessage?: string
}

export default function SelectEmails({
  accessToken,
  env = 'prod',
  id = 'emai-async-typeahead-id',
  label = 'Nike Email',
  placeholder = 'firstName.lastName@nike.com',
  subtitle = 'Search for a Nike email address',
  required = false,
  labelKey = 'email',
  hasErrors = false,
  errorMessage,
  ...props
}: SelectEmailsProps) {
  const [options, setOptions] = useState<UserView[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string | null>(null)

  const handleOnSearch = async (query: string) => {
    setIsLoading(true)
    try {
      let users: UserView[] = []
      if (env === 'qa' || env === 'prod') {
        users = await aegisApi.findUsers({ query, accessToken, env })
      } else {
        throw new Error(`Invalid environment: ${env}`)
      }
      setOptions(users)
    } catch (err) {
      setErrors('Error fetching Emails')
    } finally {
      setIsLoading(false)
    }
  }

  const handleRenderMenuItemChildren = (user: UserView) => {
    const { displayName, email }: UserView = user
    return (
      <div>
        <span>{displayName}</span>
        <br />
        <span>{email}</span>
      </div>
    )
  }

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (event): void => {
    if (required) {
      const value = event?.target?.value
      value === '' ? setErrors('This is a required field') : setErrors(null)
    }
  }

  return (
    <>
      <Label font={'title-6'} hasErrors={hasErrors} className={required ? styles.required : ''}>
        {label}
      </Label>

      <Text font={'subtitle-2'} color={'grey-7'}>
        {subtitle}
      </Text>

      <AsyncTypeahead
        id={id}
        className={`${hasErrors ? styles.errored : styles.normal}`}
        minLength={3}
        maxResults={8}
        delay={500}
        paginate={true}
        allowNew={false}
        multiple={false}
        filterBy={() => true}
        onSearch={handleOnSearch}
        onInputChange={async (query: string) => {
          if (query.length > 2) {
            await handleOnSearch(query)
          }
        }}
        isLoading={isLoading}
        renderMenuItemChildren={handleRenderMenuItemChildren}
        labelKey={labelKey}
        options={options}
        onBlur={handleOnBlur}
        emptyLabel={errors ? 'Error fetching Emails' : 'No Email matches found.'}
        placeholder={placeholder}
        {...props}
      />

      {!!errors && (
        <Text font={'legal-1'} color='red-50'>
          {String(errors)}
        </Text>
      )}

      {!!hasErrors && (
        <Text font={'legal-1'} color='red-50'>
          {String(errorMessage)}
        </Text>
      )}
    </>
  )
}
