import styles from './SelectOktaClientId.module.styl'
import { useFetchClientIds } from './hook'
import { validateClientPattern } from './validate'
import { Icon, Select } from '@nike/eds'
import { ReactNode, useEffect, useState } from 'react'

const overrideReactSelectOptions = {
  formatCreateLabel: (inputValue: string) => {
    // The default value is `Create` and it's a little confusing cause we're not creating a clientId we are using it
    return `Use "${inputValue}"`
  },
  getNewOptionData: (inputValue: string, optionLabel: ReactNode | undefined) => {
    // To validate that the clientId have the right format
    const clientIdRegExp = validateClientPattern(inputValue)

    if (!clientIdRegExp) return null
    return { value: inputValue, label: optionLabel }
  },
}

function ErrorFetchingData() {
  return (
    <div className={styles.errorMsg}>
      <Icon className={styles.icon} name='AlertCircle' size='m' />
      <span className={styles.msg}> Error fetching Client IDs</span>
    </div>
  )
}

const fetchErrorMsg =
  "Fail to fetch data, maybe your accessToken has expired or it's not the right Okta Environment"

type ClientIdOptions = { value: string; label: string }

type SelectOktaClientIdProps = {
  accessToken: string
  userEmail: string
  env?: string
  id?: string
  label?: string
  subtitle?: string
  placeholder?: string
  isMulti?: boolean
  isCreatable?: boolean
}

export default function SelectOktaClientId({
  env = 'prod',
  accessToken,
  userEmail,
  id = 'OktaClientId',
  label = 'Okta Client ID',
  subtitle = 'Select the Okta OAuth clientId(s)',
  placeholder = 'nike.niketech.my-client-id',
  isMulti = true,
  isCreatable = true,
  ...props
}: SelectOktaClientIdProps) {
  const [clientIdOptions, setClientIdOptions] = useState<ClientIdOptions[] | undefined>([])
  const [fetchError, setFetchError] = useState<string | null>(null)

  const { data, loading, error } = useFetchClientIds(accessToken, userEmail, env)

  useEffect(() => {
    setFetchError(error ? fetchErrorMsg : null)
    const selectOptions = data
      ?.map((clientId: string) => ({ value: clientId, label: clientId }))
      ?.sort((a, b) => a.value?.localeCompare(b.value, 'en', { sensitivity: 'base' }))
    setClientIdOptions(selectOptions)
  }, [loading, data, error, env])

  return (
    <Select
      {...{ id, label, subtitle, placeholder, isMulti, isCreatable, accessToken }}
      options={clientIdOptions}
      {...props}
      {...overrideReactSelectOptions}
      noOptionsMessage={() => {
        if (loading) return 'Loading...'
        if (fetchError) return <ErrorFetchingData />
        return 'Please provide a valid ClientId format. e.g. `some.client.id`'
      }}
    />
  )
}
