import { useCallback, useEffect, useState } from 'react'

type fetchAdGroupsParams = {
  accessToken?: string
  env?: string
  service?: (accessToken: string, env?: string, queryString?: string) => Promise<[]>
  isLazyFetch?: boolean
  queryString?: string
}

export function useFetchAdGroups({
  accessToken,
  env,
  service,
  isLazyFetch = false,
  queryString,
}: fetchAdGroupsParams = {}) {
  const [data, setData] = useState<[] | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const fetchData = useCallback(
    async (
      accessToken?: string,
      env?: string,
      service?: (accessToken: string, env?: string, queryString?: string) => Promise<[]>,
      queryString?: string
    ) => {
      setLoading(true)
      setError(null)

      try {
        const response = await service!(accessToken!, env, queryString)
        setData(response)
      } catch (error) {
        console.log('[HOOK]', error)
        setError(new Error(error as string))
      } finally {
        setLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    isLazyFetch && fetchData(accessToken, env, service, queryString)
  }, [fetchData, isLazyFetch, accessToken, env, service, queryString])

  return { data, loading, error, fetchData }
}
