// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectAdGroup-module__errorMsg--a7nnY .SelectAdGroup-module__icon--hXp8F{color:#f00}.SelectAdGroup-module__errorMsg--a7nnY .SelectAdGroup-module__msg--redy8{vertical-align:super}`, "",{"version":3,"sources":["webpack://./src/ad-group-select/SelectAdGroup.module.styl"],"names":[],"mappings":"AACE,0EACE,UAAO,CACT,yEACE,oBAAgB","sourcesContent":[".errorMsg\n  .icon\n    color: red\n  .msg\n    vertical-align: super\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMsg": `SelectAdGroup-module__errorMsg--a7nnY`,
	"icon": `SelectAdGroup-module__icon--hXp8F`,
	"msg": `SelectAdGroup-module__msg--redy8`
};
export default ___CSS_LOADER_EXPORT___;
