import { UserModel, UserView } from './types'

const nullUserModels = (userModel: UserModel) =>
  userModel.email !== null && userModel.displayName !== null

const toUserView = (userModel: UserModel) => ({
  email: userModel.email,
  displayName: userModel.displayName,
})

type converModelsToViews = (users: UserModel[]) => UserView[]
export const convertUserModelsToUserViews: converModelsToViews = (users) =>
  users.filter(nullUserModels).map(toUserView)
