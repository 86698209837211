import { IDLockerHint } from './IDLockerHint'
import styles from './SelectAdGroup.module.styl'
import adGroupsApi from './api'
import { useFetchAdGroups } from './hook'
import { validateAdGroupPattern } from './validate'
import { Icon, Select } from '@nike/eds'
import { debounce } from 'lodash'
import { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'

const overrideReactSelectOptions = {
  formatCreateLabel: (inputValue: string) =>
    // The default value is `Create` and it's a little confusing cause we're not creating a adGroup we are using it
    `Use "${inputValue}"`,
  getNewOptionData: (inputValue: string, optionLabel: string) => {
    if (!validateAdGroupPattern(inputValue)) return null
    return { value: inputValue, label: optionLabel }
  },
}

function ErrorFetchingData() {
  return (
    <div className={styles.errorMsg}>
      <Icon className={styles.icon} name='AlertCircle' size='m' />
      <span className={styles.msg}> Error fetching AD Groups</span>
    </div>
  )
}

const fetchErrorMsg =
  "Fail to fetch data, maybe your accessToken has expired or it's not the right Okta Environment"
const minCharacters = 3

type AdGroup = { displayName: string; label: string }
interface SelectAdGroupProps {
  env: string
  accessToken: string
  id?: string
  label?: string
  subtitle?: string
  placeholder?: string
  isMulti?: boolean
  isUnlimitedSearch?: boolean
  showIDLockerHint?: boolean
  customIDLockerMessage?: ReactNode | string
}

export default function SelectAdGroup({
  env = 'prod',
  accessToken,
  id = 'MyAdGroups',
  label = 'My AD Groups',
  subtitle = 'Select AD Groups',
  placeholder = '',
  isMulti = true,
  isUnlimitedSearch = false,
  showIDLockerHint = true,
  customIDLockerMessage, // preferably wrapped in a Text component
  ...props
}: SelectAdGroupProps) {
  const [adGroupOptions, setAdGroupOptions] = useState<
    { value: string; label: string }[] | undefined
  >([])
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [inputValue, setInputValue] = useState('')
  const { data, loading, error, fetchData } = useFetchAdGroups()

  const onInputChange = (e: string) => setInputValue(e)
  const debounceInput = useMemo(() => debounce(onInputChange, 400), [])

  useEffect(() => {
    if (!isUnlimitedSearch) {
      fetchData(accessToken, env, adGroupsApi.listAdGroups)
    }
    setAdGroupOptions([])
  }, [isUnlimitedSearch, accessToken, env, fetchData])

  useEffect(() => {
    if (isUnlimitedSearch) {
      if (inputValue.length >= minCharacters) {
        fetchData(accessToken, env, adGroupsApi.unlimitedAdGroups, inputValue)
      } else {
        setAdGroupOptions([])
      }
    }
  }, [inputValue, isUnlimitedSearch, accessToken, env, fetchData])

  useEffect(() => {
    setFetchError(error ? fetchErrorMsg : null)
    const selectOptions = data
      ?.map((adGroup) =>
        typeof adGroup === 'string'
          ? { value: adGroup, label: adGroup }
          : {
              value: (adGroup as AdGroup).displayName,
              label: (adGroup as AdGroup).displayName,
            }
      )
      ?.sort((a, b) => a.value?.localeCompare(b.value, 'en', { sensitivity: 'base' }))
    setAdGroupOptions(selectOptions)
  }, [loading, data, error])

  return (
    <>
      <Select
        {...{ id, label, subtitle, placeholder, isMulti, accessToken }}
        options={adGroupOptions}
        onInputChange={debounceInput}
        {...props}
        {...overrideReactSelectOptions}
        noOptionsMessage={() => {
          if (isUnlimitedSearch && inputValue.length < minCharacters)
            return `To start searching type at least ${minCharacters} characters`
          if (loading) return 'Loading...'
          if (fetchError) return <ErrorFetchingData />
          return 'Please provide a valid AD Group format. e.g. `some.AD.Group`'
        }}
      />
      {showIDLockerHint && <IDLockerHint customMessage={customIDLockerMessage} />}
    </>
  )
}
