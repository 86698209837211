import { useFetchWaffleIronAccounts } from './hook'
import { WaffleIronAccountOption } from './types'
import { accountDataToDropdownOptions } from './utils'
import { Select } from '@nike/eds'
import React, { useEffect } from 'react'

type SelectWaffelIronProps = {
  username: string
  accessToken: string
  env: string
  label?: string
  placeholder?: string
  id?: string
}

export default function SelectWaffleIron({
  username,
  accessToken,
  env,
  id = 'select-waffle-iron-account',
  ...props
}: SelectWaffelIronProps) {
  const [waffleAccountOptions, setWaffleAccountOptions] = React.useState<
    WaffleIronAccountOption[] | undefined
  >(undefined)
  const { data, loading, error } = useFetchWaffleIronAccounts({ username, accessToken, env })

  useEffect(() => {
    let mounted = true
    const selectOptions = accountDataToDropdownOptions(data)
    if (mounted) {
      setWaffleAccountOptions(selectOptions || undefined)
    }
    return () => {
      mounted = false
    }
  }, [data])

  return (
    <Select
      {...props}
      id={id}
      label={props?.label ?? 'Waffle Iron Account ID'}
      placeholder={props?.placeholder ?? '463456716078'}
      options={waffleAccountOptions}
      noOptionsMessage={() => {
        if (loading) return 'Loading...'
        if (error) return 'Error fetching waffle iron accounts'
        return 'Please provide a valid AWS Waffle Iron Account ID'
      }}
    />
  )
}
