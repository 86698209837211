import { AegisAPI } from './types'
import { convertUserModelsToUserViews } from './utils'

export const headers = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

export const aegisUrls = {
  qa: 'https://api.aegis.nikecloud.com/v1/qa',
  prod: 'https://api.aegis.nikecloud.com/v1/prod',
}

const aegisApi: AegisAPI = {
  findUsers: async ({ query, accessToken, env = 'prod' }) => {
    try {
      const searchUri = `${aegisUrls[env as keyof typeof aegisUrls]}/users`
      const url = `${searchUri}?count=50&startsWith=${query}`
      const response = await fetch(url, {
        method: 'GET',
        headers: headers(accessToken),
      })
      if (!response?.ok) Promise.reject(response)
      const { items } = await response.json()

      return convertUserModelsToUserViews(items)
    } catch (error) {
      console.log('[API]', error)
      throw new Error(error as string)
    }
  },
}

export default aegisApi
