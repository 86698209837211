import { WaffleIronAccount, WaffleIronAccountOption, WaffleIronAccountResponse } from './types'

export const accountDataToDropdownOptions = (
  accounts: WaffleIronAccount[] | null
): WaffleIronAccountOption[] | null =>
  accounts
    ?.map(
      (account: WaffleIronAccount): WaffleIronAccountOption => ({
        value: account.accountId,
        label: `${account.name}-${account.accountType.toLowerCase()} (${account.accountId})`,
      })
    )
    .sort((a, b) => a.label?.localeCompare(b.label || '', 'en', { sensitivity: 'base' }) ?? 0) ??
  null

export const responseDataToAccountList = (
  response: WaffleIronAccountResponse
): WaffleIronAccount[] => response?.data?.user?.accountsWithAccess
