import { ListByUserOptions, WaffleIronAccount, WaffleIronApi } from './types'
import { responseDataToAccountList } from './utils'

export const waffleIronUrls = {
  // Backend configuration is found here: https://github.com/nike-cx/cx-waffle-iron-proxy
  // /api is added because we are using a {proxy+} in api gateway
  prod: {
    read: 'https://v79pzh8cd5.execute-api.us-west-2.amazonaws.com/v1/waffleiron',
  },
}

/* Config */

export const headers = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

/* Implementation */

export const waffleIronApi: WaffleIronApi = {
  listByUser: async (options: ListByUserOptions): Promise<WaffleIronAccount[]> => {
    const { username, accessToken, env = 'prod' } = options
    const params = new URLSearchParams({ username })

    try {
      const url = `${waffleIronUrls[env as keyof typeof waffleIronUrls].read}?` + params
      const response = await fetch(url, { method: 'GET', headers: headers(accessToken) })

      if (!response?.ok) Promise.reject(response)
      const data = await response.json()

      const accountsWithAccess = responseDataToAccountList(data)
      return accountsWithAccess
    } catch (error: unknown) {
      console.log('[API]', error)
      throw new Error(error as string)
    }
  },
}

export default { waffleIronApi, headers }
