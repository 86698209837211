import { withErrorBoundary } from '../../../components/error-boundary/ErrorBoundary'
import AdGroupSelect from './ad-group-select/SelectAdGroup'
import CloudRedGuidsSelect from './cloud-red-guids-select/SelectCloudRedGuids'
import EmailsSelect from './email-select/SelectEmails'
import OktaClientIdsSelect from './okta-client-ids-select/SelectOktaClientId'
import WaffleIronSelect from './waffle-iron-select/SelectWaffleIron'

export { useFetchAdGroups } from './ad-group-select/hook'
export { useFetchCloudRedGuids } from './cloud-red-guids-select/hook'
export { useFetchClientIds } from './okta-client-ids-select/hook'
export { useFetchWaffleIronAccounts } from './waffle-iron-select/hook'
export { default as xProductUrls } from './urls'

export const SelectAdGroup = withErrorBoundary(AdGroupSelect)
export const SelectCloudRedGuids = withErrorBoundary(CloudRedGuidsSelect)
export const SelectEmails = withErrorBoundary(EmailsSelect)
export const SelectOktaClientIds = withErrorBoundary(OktaClientIdsSelect)
export const SelectWaffleIron = withErrorBoundary(WaffleIronSelect)
