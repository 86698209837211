import SelectAdGroup from '../ad-group-select/SelectAdGroup'
import SelectEmails from '../email-select/SelectEmails'
import styles from './CreateCloudRedGuidModal.module.styl'
import api from './api'
import type { CloudRedGuid, EnvironmentOptions, SelectOption } from './types'
import { cloudRedHomepages } from './utils'
import {
  Modal,
  ButtonGroup,
  Button,
  Link,
  Text,
  TextField,
  Select,
  Snackbar,
  Snack,
} from '@nike/eds'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { UserView } from 'src/email-select/types'

export type CreateCloudRedGuidModalProps = {
  visible: boolean
  setVisible: (visible: boolean) => void
  onChange: (cloudRedGuid: CloudRedGuid) => void
  accessToken: string
  env: EnvironmentOptions
  userEmail: string
}

type FormObject = {
  type: SelectOption | null
  name: string
  distributionList: SelectOption | null
  environment: SelectOption | null
  owner: UserView[]
}

const systemTypes: SelectOption[] = [
  { label: 'AWS Account', value: 'aws_account' },
  { label: 'Application', value: 'application' },
  { label: 'Azure Resource Group', value: 'azure_rg' },
  { label: 'Azure Subscription', value: 'azure_subscription' },
]

const environmentTagOptions: SelectOption[] = ['sandbox', 'nonprod', 'prod'].map((v) => ({
  label: v,
  value: v,
}))

export default function ({
  visible,
  setVisible,
  onChange,
  accessToken,
  env,
  userEmail,
}: CreateCloudRedGuidModalProps) {
  const {
    control,
    // handleSubmit,
    formState: { isValid, isSubmitting, errors },
    register,
    reset,
    getValues,
  } = useForm<FormObject>({
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const [createError, setCreateError] = useState<Error | null>(null)

  useEffect(() => {
    reset({
      type: systemTypes.find(({ value }) => value === 'application'),
      name: '',
      distributionList: null,
      environment: null,
    })
  }, [reset, visible])

  const handleSubmit = async () => {
    const { name, type, distributionList, owner, environment } = getValues()
    const createPayload = {
      name,
      owner: userEmail,
      type: type?.value || '',
      tags: {
        'nike-environment': environment?.value || '',
        'nike-distributionlist': distributionList?.value || '',
        'nike-owner': owner[0].email || '',
      },
      metadata: {},
    }
    try {
      const newCloudredGuid = await api.create({ accessToken, env, ...createPayload })
      setCreateError(null)
      onChange(newCloudredGuid)
      setVisible(!visible)
    } catch (error) {
      setCreateError(error as Error)
    }
  }

  const cloudRedHomepage = cloudRedHomepages[env]

  return (
    <Modal
      data-testid={'create-cloudred-guid-modal'}
      isOpen={visible}
      onDismiss={() => setVisible(false)}
      headerSlot={'Create CloudRed GUID'}
      footerSlot={
        <>
          <Snackbar>
            {createError && (
              <Snack id='create-error' status='error' onDismiss={() => setCreateError(null)}>
                <Text font='body-2'>
                  An error occurred creating your CloudRed GUID. Please try again or visit{' '}
                  <Link href={cloudRedHomepage} target='_blank'>
                    {cloudRedHomepage}
                  </Link>{' '}
                  to view/register systems.
                </Text>
              </Snack>
            )}
          </Snackbar>
          <ButtonGroup>
            <Button
              type='submit'
              size='small'
              disabled={!isValid || isSubmitting}
              data-external-id='select-cloudred-guid-create-guid-submitted'
              onClick={handleSubmit}
            >
              Create GUID
            </Button>
            <Button
              size='small'
              variant='secondary'
              data-external-id='select-cloudred-guid-create-guid-canceled'
              onClick={() => setVisible(!visible)}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </>
      }
      hideFade
    >
      {visible && (
        <div className={styles.modalContainer}>
          <div className={'eds-spacing--mb-12'}>
            <Controller
              name='type'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field }) => (
                <Select
                  {...field}
                  id='type'
                  label='System Type'
                  subtitle='What type of system are you registering?'
                  required={true}
                  options={systemTypes}
                />
              )}
            />
          </div>

          <div className={'eds-spacing--mb-12'}>
            <TextField
              id={'name'}
              label={'Name'}
              placeholder={'What do you call this system?'}
              hasErrors={!!errors.name}
              errorMessage={errors.name?.message || ''}
              {...register('name', { required: 'This field is required' })}
            />
          </div>

          <div className={'eds-spacing--mb-12'}>
            <Controller
              name='distributionList'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field }) => (
                <SelectAdGroup
                  {...field}
                  {...{ ref: null }}
                  label={'Distribution List'}
                  subtitle={
                    'List that includes email addresses for team members who can take action to remediate an issue on an asset/resource'
                  }
                  isMulti={false}
                  isUnlimitedSearch={true}
                  env={env}
                  accessToken={accessToken}
                />
              )}
            />
          </div>

          <div className={'eds-spacing--mb-12'}>
            <Controller
              name='environment'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field }) => (
                <Select
                  {...field}
                  id='type'
                  label='Environment'
                  subtitle='Production level of the cloud resource'
                  required={true}
                  options={environmentTagOptions}
                />
              )}
            />
          </div>

          <div className={'eds-spacing--mb-12'}>
            <Controller
              name='owner'
              control={control}
              rules={{ required: 'This field is required.' }}
              render={({ field }) => (
                <SelectEmails
                  {...field}
                  {...{ ref: null }}
                  label={'Owner'}
                  subtitle={
                    'Nike email address of the team manager; the person overseeing the build and maintenance of the app or workload.'
                  }
                  env={env}
                  accessToken={accessToken}
                />
              )}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
