import oauthAppsApi from './api'
import { useCallback, useEffect, useState } from 'react'

export function useFetchClientIds(
  accessToken: string,
  userEmail: string,
  env: string,
  service = oauthAppsApi.listClientIds
) {
  const [data, setData] = useState<string[] | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(
    async (accessToken: string, env: string) => {
      setLoading(true)
      setError(null)

      try {
        const response: string[] = await service(accessToken, userEmail, env)
        setData(response)
      } catch (error) {
        console.log('[HOOK Error]', error)
        const err = new Error(error as string)
        setError(err)
      } finally {
        setLoading(false)
      }
    },
    [service, userEmail]
  )

  useEffect(() => {
    fetchData(accessToken, env)
  }, [fetchData, accessToken, env])
  return { data, loading, error, fetchData }
}
