export function validateNikeEmailPattern(email: string): boolean {
  const pattern = /^[a-zA-Z0-9._%+-]+@nike\.com$/
  return pattern.test(email)
}

export function validateJwtPattern(jwt: string): boolean {
  const pattern = /^[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]*$/
  return pattern.test(jwt)
}

export function validateClientPattern(clientId: string): boolean {
  const pattern = /^([^.]+\.[^.]+){2}$/
  return pattern.test(clientId)
}
