import { CloudRedAPI, CloudRedGuid, CloudRedGuids } from './types'

export const headers = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

export const cloudRedUrls = {
  qa: 'https://cloudred-staging.nike.com/api/ssot/systems',
  prod: 'https://cloudred.nike.com/api/ssot/systems',
}

const cloudRedApi: CloudRedAPI = {
  list: async ({ accessToken, userEmail, env = 'prod' }): Promise<CloudRedGuids> => {
    const url = `${cloudRedUrls[env as keyof typeof cloudRedUrls]}/?owner=${userEmail}`

    try {
      const response = await fetch(url, { method: 'GET', headers: headers(accessToken) })

      if (!response?.ok) return Promise.reject(response)
      const data = await response.json()

      if (data && Array.isArray(data)) {
        return data.map(({ name, key, owner, type }) => ({ name, guid: key, owner, type }))
      } else {
        return []
      }
    } catch (error) {
      console.log('[API error]', error)
      throw new Error(error as string)
    }
  },

  create: async ({
    accessToken,
    env = 'prod',
    name,
    owner,
    metadata = {},
    type,
    tags = {},
  }): Promise<CloudRedGuid> => {
    const url = `${cloudRedUrls[env as keyof typeof cloudRedUrls]}/`
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers(accessToken),
        body: JSON.stringify({
          name,
          owner,
          metadata,
          type,
          tags,
        }),
      })

      if (!response?.ok) return Promise.reject(response)
      const data = await response.json()

      return { name: data.name, guid: data.key, owner: data.owner, type: data.type }
    } catch (error) {
      console.log('[API error]', error)
      throw new Error(error as string)
    }
  },
}

export default cloudRedApi
