import api from './api'
import { CloudRedGuids, EnvironmentOptions, FetchCloudRedGuidsOptions } from './types'
import { useCallback, useEffect, useState } from 'react'

export function useFetchCloudRedGuids({
  accessToken,
  userEmail,
  env,
  service = api.list,
}: FetchCloudRedGuidsOptions) {
  const [data, setData] = useState<CloudRedGuids | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = useCallback(
    async (accessToken: string, userEmail: string, env: EnvironmentOptions) => {
      setLoading(true)
      setError(null)

      try {
        const response = await service({ accessToken, userEmail, env })
        setData(response)
      } catch (error) {
        console.log('[HOOK Error]', error)
        setError(new Error(error as string))
      } finally {
        setLoading(false)
      }
    },
    [service]
  )

  useEffect(() => {
    if (accessToken && userEmail) {
      fetchData(accessToken, userEmail, env)
    }
  }, [accessToken, fetchData, userEmail, env])
  return { data, loading, error, fetchData }
}
