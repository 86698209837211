export const headers = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

export const urls = {
  qa: 'https://api.aegis.nikecloud.com/v1/qa',
  prod: 'https://api.aegis.nikecloud.com/v1/prod',
}

const adGroupsApi = {
  /**
   * @param string accessToken - Okta JWT for the right environment prod|qa
   * @param string env - Defaults to prod
   * @returns array - Array of clientIds
   */
  listAdGroups: async (accessToken: string, env: string = 'prod') => {
    const url = `${urls[env as keyof typeof urls]}/userinfo?claims=nike:groups`
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers(accessToken),
      })
      if (!response?.ok) Promise.reject(response)
      const data = await response.json()

      if (data['nike:groups']) return data['nike:groups']
      return data
    } catch (error) {
      console.log('[API error]', error)
      throw new Error(error as string)
    }
  },
  unlimitedAdGroups: async (
    accessToken: string,
    env: string = 'prod',
    input: string | undefined
  ) => {
    const url = `${urls[env as keyof typeof urls]}/groups?`
    try {
      const count = `${50}`
      const response = await fetch(url + new URLSearchParams({ q: input || '', count }), {
        method: 'GET',
        headers: headers(accessToken),
      })
      if (!response?.ok) Promise.reject(response)
      const data = await response.json()
      if (data['items']) return data['items']
      return data
    } catch (error) {
      console.log('[API error]', error)
      throw new Error(error as string)
    }
  },
}

export default adGroupsApi
