import { cloudRedUrls } from './cloud-red-guids-select/api'
import { aegisUrls } from './email-select/api'
import { devPortalUrls } from './okta-client-ids-select/api'
import { waffleIronUrls } from './waffle-iron-select/api'

/**
 * Example: `xProductUrls.prod.wafffleIron.read`
 *
 */
const xProductUrls = {
  qa: {
    cloudRed: {
      read: cloudRedUrls.qa,
    },
    emails: {
      read: aegisUrls.qa,
    },
    oktaClientId: {
      read: devPortalUrls.qa,
    },
  },
  prod: {
    cloudRed: {
      read: cloudRedUrls.prod,
    },
    emails: {
      read: aegisUrls.prod,
    },
    oktaClientId: {
      read: devPortalUrls.prod,
    },
    waffleIron: {
      read: waffleIronUrls.prod.read,
    },
  },
}

export default xProductUrls
